import "./Jobs.css"

import * as React from "react";
import { Button } from "react-bootstrap";

import { JobScheduleTable } from "./ui_components/JobScheduleTable";
import { Container, Spinner } from "react-bootstrap"
import { callApiServer } from "./utils/api";
import LoadingSpinner from "./ui_components/LoadingSpinner";


export default function Jobs(props) {
    const [jobSchedules, setJobSchedules] = React.useState([]);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [tableLoading, setTableLoading] = React.useState(true);
    const [refreshLoading, setRefreshLoading] = React.useState(false);
    const [statusMsg, setStatusMsg] = React.useState('');
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [deleteButtonEnabled, setDeleteButtonEnabled] = React.useState(false);

    const updateTable = () => {
      setRefreshLoading(true);
      callApiServer("/jobschedules/", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
      })
      .then(response => response.json())
      .then(data => {
        setJobSchedules(data);
        setRefreshLoading(false);
        if (firstLoad) {
          setTableLoading(false);
          setFirstLoad(false);
        }
      })
    }

    // Initial Page load updates table
    React.useEffect(() => {
        updateTable();
    }, [])

    // Subsequent refreshes to update table
    React.useEffect(() => {
      // Starts the interval when the page becomes visible
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          // Start the interval when the page is visible
          intervalId = setInterval(() => {
            updateTable();
          }, 20000);
        } else {
          // Clear the interval when the page is hidden
          clearInterval(intervalId);
        }
      };
    
      let intervalId;
      document.addEventListener('visibilitychange', handleVisibilityChange);
      handleVisibilityChange();
    
      return () => {
        // Clean up the interval and event listener on component unmount
        clearInterval(intervalId);
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    // Update the delete and cancel buttons states
    React.useEffect(() => {
      const _deleteButtonEnabled = selectedIds.length > 0
      const _cancelButtonEnabled = selectedIds.length > 0 && selectedIds.every(jobId => jobSchedules.find(job => job.id === jobId).status === "Running");
      setDeleteButtonEnabled(_deleteButtonEnabled);
      console.log("Rows Selected: ", selectedIds);
    }, [selectedIds])

    const deleteSelectedJobs = () => {
      setDeleteButtonEnabled(false);
      document.body.style.cursor = 'progress';
      let deletedJobs = [];
      let deletePromises = selectedIds.map((jobId) => {
        return callApiServer(`/jobs/${jobId}/delete`, {
          method: 'POST',
          headers: {
        'Content-Type': 'application/json'
          }
        })
        .then(response => response.json())
        .then(data => {
          deletedJobs.push(data.job_name);
        })
        .catch(error => {
          console.error("Error deleting job: ", error);
        });
      });

      Promise.all(deletePromises).then(() => {
        updateTable();
        setStatusMsg(`Deleted jobs: ${deletedJobs.join(", ")}`);
        document.body.style.cursor = 'default';
      });
    }
 
    // Refresh button styles, make transparent if refreshLoading is not true
    const refreshSpinnerColorStyle = refreshLoading ? { color: "grey" } : { color: "transparent" };
    const refreshSpinnerStaticStyle = { height: "20px", width: "20px", alignSelf: "flex-end" };
    const refreshSpinnerStyle = { ...refreshSpinnerStaticStyle, ...refreshSpinnerColorStyle };

    return (
      <Container style={{display: 'flex', flexDirection: 'column', alignContent: 'flex-start'}}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h2>Scheduled Workflows</h2>
      </div>
      <div className="workflow-history-content" style={{ margin: "0.5em", overflow: "hidden" }}>
        {tableLoading ? (
          <LoadingSpinner />
        ) : (
          <JobScheduleTable jobschedules={jobSchedules} setStatusMsg={setStatusMsg} setRowsSelected={setSelectedIds}/>
        )}
      </div>
      <p style={{paddingTop: "1em"}}>{statusMsg}</p>
      </Container>
    )
}