import './Table.css'

import * as React from 'react'

import { ScheduleData, Schedule } from './Schedule';
import { callApiServer } from '../utils/api';
import {Container, Row, Col, Button} from 'react-bootstrap';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedUniqueValues,
  SortingState,
  getExpandedRowModel,
  ExpandedState,
} from '@tanstack/react-table'

type JobSchedule = {
  id: string,
  name: string,
  script_repo_path: string,
  task_id: number,
  schedule: ScheduleData,
  permissions: number[],
  report: number,
  owner: string
}

interface TableProps {
  jobschedules: JobSchedule[]
  setStatusMsg: React.Dispatch<React.SetStateAction<string>>
  setRowsSelected: React.Dispatch<React.SetStateAction<string[]>>
}

export function JobScheduleTable(props: React.PropsWithChildren<TableProps>) {
  const { jobschedules: jobschedules, setStatusMsg, setRowsSelected } = props

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [expanded, setExpanded] = React.useState<ExpandedState>({})

  // Column Definitions
  const columnHelper = createColumnHelper<JobSchedule>()

  const columns = [
    columnHelper.display({
        id: 'Expand', 
        header: () => <span>Expand</span>,
        cell: ({ row }) => {
          return row.getCanExpand() ?
            <button
              onClick={row.getToggleExpandedHandler()}
              style={{ cursor: 'pointer', border: 'none', backgroundColor: 'transparent' }}
            >
              {row.getIsExpanded() ? '▼' : '▶'}
            </button>
           : '';
        },
    }),
    columnHelper.accessor('name', {
        header: () => <span>Name</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('script_repo_path', {
        header: () => <span>Script</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('owner', {
        header: () => <span>Owner</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
  ]

  // Table Hook
  const table = useReactTable({
    data: jobschedules,
    columns,
    state: {
      sorting,
      expanded: expanded
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: originalRow => originalRow.id,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: (row) => true,
    onExpandedChange: setExpanded
  })

  React.useEffect(() => {
    props.setRowsSelected(table.getSelectedRowModel().rows.map(row => row.id))
  }, [table.getSelectedRowModel().rows])

  const testJobSchedule = (id: string) => {
    callApiServer(`/jobschedules/${id}/test/`, {method: 'POST'})
    .then(response => {
      if (response.ok) {
        setStatusMsg(`Testing job schedule ${id}...`)
      } else {
        setStatusMsg(`Error testing job schedule ${id}`)
      }
    })
  }

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header=> (
              <th key={header.id}>
                  <div>
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {cursor: "pointer"}
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {{
                      asc: '🔼',
                      desc: '🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                
              </th>
            ))}
          </tr>
        ))}
      </thead>
      
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <tr className={index % 2 == 0? 'even-row':'odd-row'}>
              {row.getVisibleCells().map(cell => (
                // try css: word-wrap: break-word; to get text to wrap properly
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {/* If the row is expanded, render the expanded UI as a separate row with a single cell that spans the width of the table */}
            {row.getIsExpanded() && (
              <tr className={index % 2 == 0? 'even-row':'odd-row'}>
                <td colSpan={row.getAllCells().length}>
                  <Container>
                    <Row>
                      <Col>
                        <Button onClick={() => testJobSchedule(row.id)}>Test Job </Button>
                      </Col>
                      <Col>
                        <Row><Schedule initialScheduleData={row.original.schedule} onChange={(scheduleData) => console.log(scheduleData)}/></Row>
                        <Row><br/></Row>
                        <Row>
                          <Button onClick={() => console.log('clicked')}>Update Schedule (not functional)</Button>
                          <div>update status message</div>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      
    </table>
  )
}