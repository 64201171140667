import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const Wizard = ({ currentStep, nextStep, prevStep, children }) => {

  const stepCount = React.Children.count(children);
  const buttonWidth = "300px";

  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "100%", padding: "20px", width: "35  %", margin: "auto"}}>
      {React.Children.toArray(children)[currentStep - 1]}
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px",}}>
        { currentStep == 1 ? (
          <Button style={{width: buttonWidth}} onClick={nextStep}>Next</Button>
        ) : 1 < currentStep && currentStep < stepCount ? (
          <div style={{display: "flex", flexDirection: "row"}}>
            <ButtonGroup style={{width: buttonWidth}}>
              <Button variant="secondary" style={{width: "50%"}} onClick={prevStep}>Previous</Button>
              <Button disabled={currentStep >= stepCount} style={{width: "50%"}} onClick={nextStep}>Next</Button>
            </ButtonGroup>
          </div>
        ) : currentStep >= stepCount? (
          <Button variant="secondary" style={{width: buttonWidth}} onClick={prevStep}>Previous</Button>
        ) : null}
      </div>
    </div>
  );
};

export default Wizard;
