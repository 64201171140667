import * as React from 'react'

import { Container, Form, FormControl, InputGroup, Button } from "react-bootstrap"

interface ScheduleProps {
    initialScheduleData: ScheduleData,
    onChange: (scheduleData: ScheduleData) => void
}

export interface ScheduleData {
    dateTime: string
    recur: boolean
    recurrencePattern: string
    recurrenceInterval: number
    timezone: string
    validationError: string
}

export function Schedule(props: React.PropsWithChildren<ScheduleProps>) {
    const { initialScheduleData, onChange } = props
    const [recur, setRecur] = React.useState<boolean>(false)
    const [recurrencePattern, setRecurrencePattern] = React.useState<string>("")
    const [recurrenceInterval, setRecurrenceInterval] = React.useState<number>(1)
    const [dateTime, setDateTime] = React.useState<string>("")
    const [timezone, setTimezone] = React.useState<string>("UTC")
    const [validationError, setValidationError] = React.useState<string>("")

    const timezones = [
        "UTC",
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Los_Angeles",
        "America/Anchorage",
        "America/Phoenix",
        "America/Indiana/Indianapolis",
        "Asia/Singapore"
    ]

    React.useEffect(() => {
        const scheduleData = {
            dateTime,
            recur,
            recurrencePattern,
            recurrenceInterval,
            timezone,
            validationError
        }
        // console.log("Schedule Data:", scheduleData)
        onChange(scheduleData)
    }, [dateTime, recur, recurrencePattern, recurrenceInterval, timezone, validationError])

    React.useEffect(() => {
        // Input validation
        if (!dateTime) {
            setValidationError("Please select a date and time.")
        } else if (recur && (!recurrencePattern || recurrenceInterval < 1)) {
            setValidationError("Please select a recurrence pattern and interval.")
        } else {
            setValidationError("")
        }
    }, [dateTime, recur, recurrencePattern, recurrenceInterval])

    React.useEffect(() => {
        if (initialScheduleData !== undefined && initialScheduleData !== null) {
            setDateTime(initialScheduleData.dateTime)
            setRecur(initialScheduleData.recur)
            setRecurrencePattern(initialScheduleData.recurrencePattern)
            setRecurrenceInterval(initialScheduleData.recurrenceInterval)
            setTimezone(initialScheduleData.timezone)
            setValidationError(initialScheduleData.validationError)
        }
    }, [])

    return (
        <Container className="schedule">
            <Form.Group>
                <Form.Label>Start Date</Form.Label>
                <Form.Control 
                    aria-label="Date and time" 
                    type="datetime-local"
                    value={dateTime}
                    onChange={e => setDateTime(e.target.value)}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Timezone</Form.Label>
                <Form.Control 
                    as="select" 
                    value={timezone} 
                    onChange={e => setTimezone(e.target.value)}
                >
                    {timezones.map(tz => (
                        <option key={tz} value={tz}>{tz}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Check 
                type="checkbox"
                label="Repeat?"
                checked={recur}
                onChange={e => setRecur(e.target.checked)}
            />
            {recur && 
            <>
                <div key='default-radio' className="mb-4">
                    <Form.Check 
                        label="Daily" 
                        type="radio" 
                        id="recurrence-daily" 
                        name="recurrence-pattern" 
                        checked={recurrencePattern === "daily"}
                        onChange={() => setRecurrencePattern("daily")}
                    />
                    <Form.Check 
                        label="Weekly" 
                        type="radio" 
                        id="recurrence-weekly" 
                        name="recurrence-pattern" 
                        checked={recurrencePattern === "weekly"}
                        onChange={() => setRecurrencePattern("weekly")}
                    />
                    <Form.Check 
                        label="Monthly" 
                        type="radio" 
                        id="recurrence-monthly" 
                        name="recurrence-pattern" 
                        checked={recurrencePattern === "monthly"}
                        onChange={() => setRecurrencePattern("monthly")}
                    />
                    <Form.Check 
                        label="Yearly" 
                        type="radio" 
                        id="recurrence-yearly" 
                        name="recurrence-pattern" 
                        checked={recurrencePattern === "yearly"}
                        onChange={() => setRecurrencePattern("yearly")}
                    />
                </div>
                {/* TODO: recurrence every n (days/weeks/months/years) */}
                {/* <Form.Label>Recur every</Form.Label>
                <FormControl 
                    type="number" 
                    min="1" 
                    aria-label="Recurrence interval" 
                    value={recurrenceInterval}
                    onChange={e => setRecurrenceInterval(parseInt(e.target.value))}
                />
                <Form.Label>{recurrencePattern === "daily" ? "days" : recurrencePattern === "weekly" ? "weeks" : recurrencePattern === "monthly" ? "months" : "years"}</Form.Label> */}
            </>
            }
            {validationError && <div className="text-danger">{validationError}</div>}
        </Container>
    )
}