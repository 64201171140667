import "./Resource.css"

import * as React from "react"
import { Button, Card, Col, Container, FormControl, InputGroup, Row, Spinner, Stack } from "react-bootstrap"
import { useMsal } from "@azure/msal-react";
import { useParams } from 'react-router-dom';

import DocumentEmbed from "./ui_components/DocumentEmbed"
import LoadingSpinner from "./ui_components/LoadingSpinner"
import Workflows from "./Workflow"
import { callApiServer } from "./utils/api";
import RunbookTemplate from "./ui_components/RunbookTemplate";

export default function Resource({ props }): JSX.Element {

	// React Router hook to get the resourceId from the URL
	const resourceId = useParams().resourceId

	// State intialization
	const [resource, setResource] = React.useState<any>(null)
	const [resourceDescription, setResourceDescription] = React.useState<string>("")

	React.useEffect(() => {
		callApiServer(`/docs/${resourceId}`, {'method': 'GET'})
		.then(response => response.json())
		.then(response => {
			setResource(response)
			setResourceDescription((response.type === "document" || response.type === "runbookTemplate") ? response.synopsis : response.description)
		})
		.catch(error => {
			console.error('There was an error!', error);
		})
	}, [resourceId]);


	return (
		<>
			{resource === null || resource === undefined? 
				<LoadingSpinner />
			:
				<>
					<div className="resource-header">
						<h1>{resource.name}</h1>
						<div className="resource-description" dangerouslySetInnerHTML={{ __html: resourceDescription }}/>
					</div>
					<br />
					<Container className="resource-body">
						{resource.type === "document" ? (
							<DocumentEmbed owlId={resource.owl_id} />
						) : resource.type === "runbookTemplate" ? (
							<RunbookTemplate steps={resource.steps} template_id={resourceId} />
						) : (
							<Workflows selectedResource={resource} />
						)}
					</Container>
				</>
			}
		</>
	  )

}