import "./Workflow.css";

import React, { useEffect } from "react"
import { Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Stack } from "react-bootstrap"
import { callApiServer, downloadJobOutput } from "./utils/api";
import { Schedule } from "./ui_components/Schedule";
import Report from "./ui_components/Report";
import WorkflowArgumentsForm from "./ui_components/WorkflowArgumentsForm";
import Wizard from "./components/Wizard";

export default function Workflows(props) {
  // State Initialization
  const [loading, setLoading] = React.useState(false);
  const [downloadDisabled, setDownloadDisabled] = React.useState(true);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [executeDisabled, setExecuteDisabled] = React.useState(true);
  const [jobStatus, setJobStatus] = React.useState(null);
  const [jobStatusMsg, setJobStatusMsg] = React.useState("");
  const [ghStatusMsg, setGhStatusMsg] = React.useState("");
  const [workflowNameErrorMsg, setJobNameErrorMsg] = React.useState("");
  const [filledOut, setFilledOut] = React.useState(false);
  const [scheduleEnabled, setScheduleEnabled] = React.useState(false);
  const [jobRequest, setJobRequest] = React.useState({
    type: props.selectedResource.type,
    name: "",
    filearguments: [],
    schedule: null,
    report: null,
    ...(props.selectedResource.repo_path && {script_repo_path: props.selectedResource.repo_path}),
    ...(props.selectedResource.repo && {repo: props.selectedResource.repo}),
    ...(props.selectedResource.provider && {provider: props.selectedResource.provider}),
    parameters: props.selectedResource.parameters
      .reduce((acc, parameter) => {
        acc[parameter.input_name] = {
          value: parameter.datatype === 'choices' ? parameter.choices[0] : null,
          required: parameter.required,
          type: parameter.datatype
        };
        return acc;
    }, {}),
  });
  const [jobID, setJobID] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(1);

  const ExecutionTime = {
    ASAP: 'ASAP',
    SCHEDULE: 'Schedule'
  };
  const [executionTime, setExecutionTime] = React.useState(jobRequest.schedule !== null ? ExecutionTime.SCHEDULE : ExecutionTime.ASAP);
  const [executionStatusMsg, setExecutionStatusMsg] = React.useState("");

  const requestJobOrSchedule = () => {
    if (executionTime === ExecutionTime.ASAP) {
      requestJob();
    } else {
      requestSchedule();
    }
  };

  useEffect(() => {
    // Check all required arguments are filled out
    const requiredArgsFilled = Object.values(jobRequest.parameters).every(param => {
      if (param.required) {
        let filled = param.value !== null && param.value !== "";
        return filled;
      }
          return true;
    });

    // Check if the schedule is valid
    let scheduleValid = false;
    let reportValid = false;

    if (scheduleEnabled) {
      // Check the schedule
      scheduleValid = jobRequest.schedule !== null;
      if (jobRequest.schedule !== null) {
        scheduleValid = jobRequest.schedule.validationError == "";                  // valid if no validation error
      }

      // Check the report
      reportValid = jobRequest.report !== null;
      if (jobRequest.report !== null) {
        reportValid = jobRequest.report.validationError == "";                      // valid if no validation error
      }

      // A schedule requires a report, so the schedule is only valid if the report is valid
      scheduleValid = scheduleValid && reportValid;
    } else {
      scheduleValid = true;
    }

    // Check if the job/schedule name is valid
    const nameFilled = jobRequest.name !== "" && workflowNameErrorMsg === "";

    setFilledOut(requiredArgsFilled && scheduleValid && nameFilled);
  }, [jobRequest, scheduleEnabled, jobRequest.name, workflowNameErrorMsg]);

  // Disable the download button unless a job is finished
  useEffect(() => {
    if (!filledOut) {
      setDownloadDisabled(true)
      setGhStatusMsg("")
      setLoading(false)
      setExecuteDisabled(true)
    } else if (jobStatus === null) {
      setDownloadDisabled(true)
      setGhStatusMsg("")
      setLoading(false)
      setExecuteDisabled(false)
    } else if (['Requested', 'Queued', 'Running'].includes(jobStatus)) {
      setDownloadDisabled(true)
      setGhStatusMsg(jobStatus? `Your workflow is ${jobStatus.toLowerCase()}.` :"" )
      setLoading(jobStatus !== null)
      setExecuteDisabled(true)
    } else if (['Finished'].includes(jobStatus)) {
      setDownloadDisabled(false)
      setGhStatusMsg(jobStatusMsg !== "" ? jobStatusMsg : "Your workflow is finished.")
      setLoading(false)
      setExecuteDisabled(true)
    } else if (['Failed'].includes(jobStatus)) {
      setDownloadDisabled(true)
      setGhStatusMsg(jobStatusMsg !== "" ? jobStatusMsg : "Your workflow has failed. Double check your credentials and try again, or contact Lee Reese (Lee.Reese@NewEraTech.com) with your output files.")
      setLoading(false)
      setExecuteDisabled(false)
    } else {
      setExecuteDisabled(true)
      setGhStatusMsg("")
    }
  }, [jobStatus, workflowNameErrorMsg, jobRequest.name, filledOut])

  const buildJobRequestForm = () => {
    // Convert formData to FormData object
    const jobRequestForm = new FormData();
    Object.entries(jobRequest).forEach(([key, value]) => {
      if (['parameters', 'schedule', 'report'].includes(key)) {
        jobRequestForm.append(key, JSON.stringify(value));
      } else if (key === 'filearguments') {
        value.forEach(file => jobRequestForm.append('filearguments', file))
      } else {
        jobRequestForm.append(key, value);
      }
    });

    return jobRequestForm;
  }

  const requestJob = async () => {
    // Convert formData to FormData object
    const jobRequestForm = buildJobRequestForm();
    const endpoint = "/jobs/";

    // Hit django-server endpoint
    setJobStatus('Requested')
    callApiServer(endpoint, {
      method: 'POST',
      body: jobRequestForm
    })
    .then(response => response.json())
    .then(data => {
      setJobStatus(data.status)
    
      if (data.status === "Queued") {
        setJobID(data.id);
        loopGetArtifact(4000, data.id);
      } else {
        setJobStatus("Failed")
        console.log(data.message);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  const requestSchedule = async () => {
    // Convert formData to FormData object
    const jobRequestForm = buildJobRequestForm();
    const endpoint = "/jobschedules/";

    // Hit django-server endpoint
    setJobStatus('Requested')
    callApiServer(endpoint, {
      method: 'POST',
      body: jobRequestForm
    }).then(response => response.json())
    .then(data => {
      console.log(data);
      setGhStatusMsg(`Your job is scheduled. It's ID is ${data.id}. You can view it in the Sheduled Workflows page.`);
    })
    .catch(error => {
      console.error(error);
      setGhStatusMsg("There was an error scheduling your job. Please try again. If the issue persists, contact Lee.Reese@NewEraTech for assistance.");
    });
  }

  // Poll the django-server for the job status
  const loopGetArtifact = (interval, UID) => {
    // Hit django-server/get-artifact/
    callApiServer(`/jobs/${UID}/status`, {'method': 'GET'})
    .then(response => response.json())
    .then(data =>{
      setJobStatus(data.status)
      // if there is a data.message, setJobStatusMsg to it
      if (data.message) {
        setJobStatusMsg(data.message)
      }
      if (["Running", "Queued"].includes(data.status)) { 
        setTimeout(loopGetArtifact.bind(null, interval, UID), interval);
      } else if (data.status === "Finished") {
        setDownloadUrl(data.downloadUrl);
      }
    }).catch(error => {
      console.error(error);
    });
  }

  const updateJobRequestArguments = (name, strArg = null, fileArg = null) => {
    const argument = fileArg !== null ? fileArg.name : strArg;

    setJobRequest(prevState => {
      const updatedFormData = {
        ...prevState,
        parameters: {
          ...prevState.parameters,
          [name]: {
            ...prevState.parameters[name],
            value: argument,
          }
        }
      };

      const datatype = prevState.parameters[name].type;

      // Add the file to the job request object. If the file was set to null, delete the previous file.
      if (datatype === "file") {
        if (fileArg !== null) {
          updatedFormData.filearguments.push(fileArg);
        } else {
          const previousFilename = prevState.parameters[name].value;
          updatedFormData.filearguments = updatedFormData.filearguments.filter(file => file.name !== previousFilename);
        }
      }

      console.log('updatedFormData', updatedFormData);

      return updatedFormData;
    });
  }

  const handleWorkflowNameChange = (e) => {
    const value = e.target.value;
    const disallowedChars = ['/', '\0', ' ', '*', '?', '[', ']', '!', '\'', '"', '$', '&', '(', ')', '|', ';', '<', '>', '#', '~', '{', '}', '%', '^'];
    const hasDisallowedChars = disallowedChars.some(char => value.includes(char));

    if (hasDisallowedChars) {
      setJobNameErrorMsg(`Filename cannot contain the following characters: ${disallowedChars.join(' ')}`);
    } else {
      setJobNameErrorMsg('');  // Clear the error message
      setJobRequest(prevState => ({
        ...prevState,
        name: value
      }));
    }
  }

  const handleScheduleChange = (schedule) => {
    setJobRequest(prevState => ({
      ...prevState,
      schedule: schedule
    }));

    if (schedule !== null) {
      setScheduleEnabled(true);
    }
  }

  const handleReportChange = (report) => {
    setJobRequest(prevState => ({
      ...prevState,
      report: report
    }));
  }

  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  return (
    <Wizard currentStep={currentStep} nextStep={nextStep} prevStep={prevStep}>
      <WorkflowArgumentsForm args={jobRequest.parameters} parameters={props.selectedResource.parameters} updateJobRequestArguments={updateJobRequestArguments} />
      <Report reportData={jobRequest.report} onChange={handleReportChange} />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        <h3>Execution Time</h3>
        <Form>
          <Form.Group>
            <Form.Check
              type="radio"
              label={ExecutionTime.ASAP}
              value={ExecutionTime.ASAP}
              checked={executionTime === ExecutionTime.ASAP}
              onChange={() => setExecutionTime(ExecutionTime.ASAP)}
            />
            <Form.Text>Execute the workflow now</Form.Text>
            <Form.Check
              type="radio"
              label={ExecutionTime.SCHEDULE}
              value={ExecutionTime.SCHEDULE}
              checked={executionTime === ExecutionTime.SCHEDULE}
              onChange={() => setExecutionTime(ExecutionTime.SCHEDULE)}
            />
            <Form.Text>Execute the workflow at a later time and optionally recur</Form.Text>
          </Form.Group>
        </Form>
        {executionTime === ExecutionTime.SCHEDULE &&
          <>
            <br />
            <Schedule initialScheduleData={jobRequest.schedule} onChange={handleScheduleChange} />
          </>
        }
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignSelf: "flex-end", marginTop: "20px" }}>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Workflow Name"
              onChange={handleWorkflowNameChange}
              value={jobRequest.name}
            />
            <Button
              disabled={executeDisabled}
              onClick={requestJobOrSchedule}
            >
              Request Workflow
            </Button>
          </InputGroup>
          <p style={{width: 'min-content'}}>
            {ghStatusMsg}
          </p>
        </div>
        <br />
        <div className="button-group col-4">
          <Button style={{marginLeft: "5px", boxShadow: "none"}}
                  variant="secondary"
                  onClick={() => {downloadJobOutput(jobID); setDownloadDisabled(true);}}
                  disabled={downloadDisabled}>
            <Spinner
              as="span"
              animation={loading? "border": false}
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Download Output
          </Button>
        </div>
      </div>
    </Wizard>
  );
}
