import './Table.css'

import Checkbox from './Checkbox'

import * as React from 'react'
import { downloadJobOutput } from '../utils/api';
import Badge from 'react-bootstrap/Badge';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedUniqueValues,
  SortingState,
} from '@tanstack/react-table'

type Job = {
  name: string,
  id: string,
  script_repo_path: string,
  status: string,
  created_on: string,
  updated_at_datetime: string,
  execution_time: string,
  status_message: string,
}

interface TableProps {
  jobs: Job[]
  setStatusMsg: React.Dispatch<React.SetStateAction<string>>
  setRowsSelected: React.Dispatch<React.SetStateAction<string[]>>
}

export function JobTable(props: React.PropsWithChildren<TableProps>) {
  const { jobs, setStatusMsg } = props

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [downloadedJobs, setDownloadedJobs] = React.useState<string[]>([])
 

  // Cell Definititions
  const downloadCell = (row) => {
    const owl_id = row.id
    const fileName = row.name

    if (row.status === 'Finished' && row.status !== 'Downloaded' && !downloadedJobs.includes(row.id)) {
      return (
        <a href="javascript:void(0);" onClick={() => {downloadJobOutput(owl_id, fileName); setDownloadedJobs([...downloadedJobs, row.id])}}>
          Download
        </a>
      );
    }
    else {
      return (
        <></>
      );
    }
  }

  const elapsedTimeCell = (row) => {
    const createdDate = new Date(row.created_on);
    const elapsedTimeCacheKey = `workflow-history.${row.id}.elapsedTime`;

    const [elapsedTime, setElapsedTime] = React.useState<string>(() => {
      const savedElapsedTime = localStorage.getItem(elapsedTimeCacheKey);
      return savedElapsedTime || "";
    });
    
    // Save the state to localStorage whenever it changes
    React.useEffect(() => {
      localStorage.setItem(elapsedTimeCacheKey, elapsedTime);
    }, [elapsedTime]);

    const getElapsedTime = (createdDate) => {
      const currentDate = new Date();
      const elapsedTimeMs = currentDate.getTime() - createdDate.getTime();
      const hours = Math.floor(elapsedTimeMs / (1000 * 60 * 60));
      const minutes = Math.floor((elapsedTimeMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((elapsedTimeMs % (1000 * 60)) / 1000);
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;

      const parts = [
        days > 0 ? `${days}d` : '',
        remainingHours > 0 ? `${remainingHours}h` : '',
        minutes > 0 ? `${minutes}m` : '',
        seconds > 0 ? `${seconds}s` : ''
      ].filter(Boolean);

      return parts.join(' ');
    }


    React.useEffect(() => {
      if (row.execution_time !== "") {
        setElapsedTime(row.execution_time);
      }

      const interval = setInterval(() => {
        const _elapsedTime = getElapsedTime(createdDate);
        
        setElapsedTime(_elapsedTime);
      }, 1000);

      return () => clearInterval(interval);

    }, [createdDate, row.execution_time]);

    return <>{elapsedTime}</>;
  }

  const statusCell = (status) => {
    switch (status) {
      case 'Queued':
      case 'Running':
        return (
          <Badge bg="primary">{status}</Badge>
        );
      case 'Cancelled':
        return (
          <Badge bg="warning">{status}</Badge>
        );
      case 'Finished':
      case 'Downloaded':
        return (
          <Badge bg="success">{status}</Badge>
        );
      case 'Failed':
        return (
          <Badge bg="danger">{status}</Badge>
        );
      default:
        break;
    }
  }

  const checkBoxHeader = (table) => {
    return (
      <div className='centered-checkbox-cell'>
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </div>
  )}

  const checkBoxCell = (row) => {
    return (
      <div className='centered-checkbox-cell'>
        <Checkbox
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      </div>
  )}

  // Column Definitions
  const columnHelper = createColumnHelper<Job>()

  const columns = [
    columnHelper.display({
        id: 'select', 
        header: () => checkBoxHeader(table),
        cell: props => checkBoxCell(props.row),
    }),
    columnHelper.accessor('name', {
        header: () => <span>Workflow Title</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('script_repo_path', {
        header: () => <span>Script</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('status', {
        header: () => <span>Status</span>,
        cell: cell => statusCell(cell.getValue()),
        enableSorting: false,
    }),
    columnHelper.display({
      id: 'elapsed_time',
      header: () => <span>Elapsed Time</span>,
      cell: props => elapsedTimeCell(props.row.original),
      enableSorting: false,
    }),
    columnHelper.display({
      id: 'download',
      header: () => <span>Download</span>,
      cell: props => downloadCell(props.row.original),
    }),
  ]

  // Table Hook
  const table = useReactTable({
    data: jobs,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: originalRow => originalRow.id,
  })

  React.useEffect(() => {
    props.setRowsSelected(table.getSelectedRowModel().rows.map(row => row.id))
  }, [table.getSelectedRowModel().rows])

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header=> (
              <th key={header.id}>
                  <div>
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {cursor: "pointer"}
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {{
                      asc: '🔼',
                      desc: '🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                
              </th>
            ))}
          </tr>
        ))}
      </thead>
      
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index % 2 == 0? 'even-row':'odd-row'}>
            {row.getVisibleCells().map(cell => (
              // try css: word-wrap: break-word; to get text to wrap properly
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      
    </table>
  )
}