import * as React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const styleStringToObject = (styleString) => {
  return styleString.split(';')
    .filter(style => style.trim() !== '')
    .map(style => style.split(':'))
    .reduce((acc, [property, value]) => ({
      ...acc,
      [property.trim()]: value.trim(),
    }), {});
};

const WorkflowArgumentsForm = ({ parameters, updateJobRequestArguments, args }) => {

  const renderInputField = (parameter) => {
    const formControlType =
      parameter.datatype === 'string' ? 'input' :
      parameter.datatype === 'secret' ? 'password' :
      parameter.datatype === 'file' ? 'file' :
      parameter.datatype === 'choices' ? null :
      'input';
    
    let style = { width: "100%" };

    if (parameter.style) {
      const customStyle = styleStringToObject(parameter.style);
      style = { ...style, ...customStyle };
    }

    const renderTextInput = () => (
      <FloatingLabel label={parameter.display_name}>
        <Form.Control
          style={style}
          name={parameter.input_name}
          type={formControlType}
          placeholder={`Enter ${parameter.display_name.toLowerCase()}`}
          ref={parameter.ref}
          onChange={(e) => updateJobRequestArguments(parameter.input_name, parameter.datatype === 'file' ? null : e.target.value, parameter.datatype === 'file' ? e.target.files[0] : null)}
          autoComplete={parameter.datatype === 'secret' ? 'off' : 'on'}
          value={args[parameter.input_name]?.value || null}
        />
      </FloatingLabel>
    );

    const renderSelect = () => {
      const options = parameter.choices.map(choice => ({ value: choice, label: choice }));
      let maxChoiceLength = Math.max(...parameter.choices.map(choice => choice.length))
      let width = "10em";
      if (maxChoiceLength > 10) {
        width = `${maxChoiceLength+2}ch`;
      }
      return (
        <FloatingLabel label={parameter.display_name}>
          <Form.Select
            defaultValue={args[parameter.input_name]?.value || options[0]}
            onChange={(e) => updateJobRequestArguments(parameter.input_name, e.target.value, null)}
            style={style}
          >
            {parameter.choices.map((choice: string) => (
              <option key={choice} value={choice}>{choice}</option>
            ))}
          </Form.Select> 
        </FloatingLabel>
      );
    };

    const renderFileInput = () => (
      <>
        <Form.Label>{parameter.display_name}</Form.Label>
        <Form.Control
          style={style}
          name={parameter.input_name}
          type={formControlType}
          onChange={(e) => updateJobRequestArguments(parameter.input_name, parameter.datatype === 'file' ? null : e.target.value, parameter.datatype === 'file' ? e.target.files[0] : null)}
        />
      </>
    );

    return (
        <Form.Group>
          {
            parameter.datatype === 'choices' ? renderSelect() :
            parameter.datatype === 'file' ? renderFileInput() :
            renderTextInput()
          }
          <Form.Text style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{ __html: parameter.description }} />
          <Form.Text>{(args[parameter.input_name]?.value !== null && parameter.datatype === "file") && ` - You uploaded: ${args[parameter.input_name]?.value}`}</Form.Text>
        </Form.Group>
    );
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em', width: '100%'}}>
      <h3>Workflow Arguments</h3>
      {parameters.map(renderInputField)}
    </div>
  );
};

export default WorkflowArgumentsForm;
