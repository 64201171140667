import * as React from 'react';
import { Button } from 'react-bootstrap';

interface ListInputProps {
  items: string[],
  onChange: (items: string[]) => void
}

function ListInput(props: ListInputProps) {
  const [items, setItems] = React.useState(props.items || []);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    props.onChange(items);
  }, [items]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddItem = () => {
    if (inputValue.trim() !== '') {
      setItems([...items, inputValue]);
      setInputValue('');
    }
  };

  const handleRemoveItem = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter item"
      />
      <Button onClick={handleAddItem}>Add</Button>
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item}
            <Button onClick={() => handleRemoveItem(index)}>Remove</Button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ListInput;