import * as React from 'react';
import { Container, Form, FloatingLabel } from 'react-bootstrap';
import ListInput from './ListInput';

type OneDriveReportData = {
  client_id: string,
  client_secret: string,
  tenant_id: string,
  drive_id: string,
  target_folder: string
}


type EmailReportData = {
  emails: string[]
}

type ReportData = {
    type: string,
    data: OneDriveReportData | EmailReportData,
    validationError: string
}

interface ReportProps {
  onChange: (reportData: ReportData) => void,
  reportData: ReportData,
}


const Report = (props: ReportProps) => {
  const [selectedOption, setSelectedOption] = React.useState('OWL');
  const [clientId, setClientId] = React.useState('');
  const [clientSecret, setClientSecret] = React.useState('');
  const [tenantId, setTenantId] = React.useState('');
  const [driveId, setDriveId] = React.useState('');
  const [targetFolder, setTargetFolder] = React.useState('');
  const [emails, setEmails] = React.useState<string[]>([]);
  const [validationError, setValidationError] = React.useState('');


  // Set state based on props.reportData if it exists
  React.useEffect(() => {
    if (props.reportData !== null) {
      if (props.reportData.type === 'email') {
        const data = props.reportData.data as EmailReportData;
        setEmails(data.emails);
      } else if (props.reportData.type === 'onedrive') {
        const data = props.reportData.data as OneDriveReportData;
        setClientId(data.client_id);
        setClientSecret(data.client_secret);
        setTenantId(data.tenant_id);
        setDriveId(data.drive_id);
        setTargetFolder(data.target_folder);
      }
      setSelectedOption(props.reportData.type);
    }
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleEmailsChange = React.useCallback((newEmails: string[]) => {
    setEmails(newEmails);
  }, []);

  React.useEffect(() => {
    // Build the report data object
    let type = selectedOption;
    let data;

    if (selectedOption === 'onedrive') {
      data = {
          client_id: clientId,
          client_secret: clientSecret,
          tenant_id: tenantId,
          drive_id: driveId,
          target_folder: targetFolder
        }
    } else if (selectedOption === 'email') {
      data = {
          emails
        }
    }

    const reportData = {
      type,
      data,
      validationError
    }

    // Call the parent onChange handler
    props.onChange(reportData);
  }, [selectedOption, emails, clientId, clientSecret, tenantId, driveId, targetFolder, validationError]);

  React.useEffect(() => {
    // Input validation
    if (selectedOption === 'email' && emails.length === 0) {
      setValidationError('Please enter at least one email address.');
    } else if (selectedOption === 'onedrive' && (!clientId || !clientSecret || !tenantId || !driveId || !targetFolder)) {
      setValidationError('Please enter all required fields.');
    } else {
      setValidationError('');
    }
  }
  , [selectedOption, emails, clientId, clientSecret, tenantId, driveId, targetFolder]);

  return (
    <div>
      <h3>Report</h3>
      <Form>
        <Form.Check 
          type="radio" 
          label="OWL" 
          value="OWL" 
          checked={selectedOption === 'OWL'} 
          onChange={handleOptionChange} 
        />
        <Form.Text>Workflow report will be available to download in the "Workflow History" page.</Form.Text>
        <Form.Check 
          type="radio" 
          label="Email" 
          value="email" 
          checked={selectedOption === 'email'} 
          onChange={handleOptionChange} 
        />
        <Form.Text>Workflow report will be emailed to the selected recipients</Form.Text>
        <Form.Check 
          type="radio" 
          label="OneDrive" 
          value="onedrive" 
          checked={selectedOption === 'onedrive'} 
          onChange={handleOptionChange} 
        />
        <Form.Text>Workflow report will be uploaded to a OneDrive location using a service principal</Form.Text>
        </Form>
        <br />
        {selectedOption === 'email' ? (
          // Render email-specific content here
          <ListInput items={emails} onChange={handleEmailsChange}/>
        ) :  selectedOption === 'onedrive' ? (
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'stretch', gap: '1em'}}>
            <Form.Group controlId="formClientId">
              <FloatingLabel label="Client ID">
                <Form.Control 
                  type="text" 
                  placeholder="Enter Client ID" 
                  value={clientId} 
                  onChange={(e) => setClientId(e.target.value)} 
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formClientSecret">
              <FloatingLabel label="Client Secret">
                <Form.Control 
                  type="password" 
                  placeholder="Enter Client Secret" 
                  value={clientSecret} 
                  onChange={(e) => setClientSecret(e.target.value)} 
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formTenantId">
              <FloatingLabel label="Tenant ID">
                <Form.Control 
                  type="text" 
                  placeholder="Enter Tenant ID" 
                  value={tenantId} 
                  onChange={(e) => setTenantId(e.target.value)} 
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formDriveId">
              <FloatingLabel label="Drive ID">
                <Form.Control 
                  type="text" 
                  placeholder="Enter Drive ID" 
                  value={driveId} 
                  onChange={(e) => setDriveId(e.target.value)} 
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group controlId="formTargetFolder">
              <FloatingLabel label="Target Folder">
                <Form.Control 
                  type="text" 
                  placeholder="Enter Target Folder" 
                  value={targetFolder} 
                  onChange={(e) => setTargetFolder(e.target.value)} 
                />
              </FloatingLabel>
            </Form.Group>
          </div>
        ) : (
          <React.Fragment />
        )}
      {validationError && <div className="text-danger">{validationError}</div>}
    </div>
  );
};

export default Report;
